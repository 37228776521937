.Dashboard__container {
    margin: 2em 2em 0 2em;
}

.Dashboard__container_kpi {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* width: 80%; */
    min-width: 700px;
    max-width: 1000px;
}

.Dashboard__kpi {
    border-radius: 8px;
    width: 12em;
    -webkit-box-shadow: 3px 4px 11px -3px rgba(143, 143, 143, 1);
    -moz-box-shadow: 3px 4px 11px -3px rgba(143, 143, 143, 1);
    box-shadow: 3px 4px 11px -3px rgba(143, 143, 143, 1);
}

.Dashboard__container_charts {
    margin-top: 1.5em;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.Dashboard__container_list {
    display: flex;
    width: 49%;
    justify-content: space-between;
}

.Dashboard__list {
    width: 48%;
    background: #fff;
    border-radius: 8px;
    -webkit-box-shadow: 3px 4px 11px -3px rgba(143, 143, 143, 1);
    -moz-box-shadow: 3px 4px 11px -3px rgba(143, 143, 143, 1);
    box-shadow: 3px 4px 11px -3px rgba(143, 143, 143, 1);
}
